.gameFoot {
  z-index: 5;
  padding: 30px 0;
  display: flex;
  justify-content: space-evenly;
  background-color: rgb(12, 12, 12);
  font-family: Montserrat-Regular;
}

.gameFoot div {
  width: 300px;
}

.footTitle {
  color: black;
  height: 40px;
  margin-bottom: 25px;
  padding: 35px;
  background-size: 100%;
  background-position: 0%;
  background-repeat: no-repeat;
  font-weight: lighter;
  font-family: Montserrat-SemiBold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footAbout {
  background-image: url("../../Assets/ui/white-brush-stroke-png.png");
}

.footFollow {
  background-image: url("../../Assets/ui/white-grunge-brush-stroke-9.png");
}

.gameFoot div p {
  color: white;
  font-size: 18px;
  padding: 5px;
}

.infoLink:hover {
  color: black;
  cursor: pointer;
  background-size: 100%;
  background-position: 0%;
  background-image: url("../../Assets/ui/white-grunge-brush-stroke-4.png");
  background-repeat: no-repeat;
}

.icon {
  width: 50px;
  cursor: pointer;
  object-fit: contain;
  margin: 0 0.5rem;
  transition: transform 0.3s ease-out;
}

.icon:hover {
  transform: scale(1.2);
}

.email:hover {
  cursor: pointer;
}

.socialArea {
  text-align: center;
}

#discordIcon {
  width: 55px;
}

.socialSection {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

@media screen and (max-width: 750px) {
  .gameFoot {
    padding: 0.5rem 0;
    flex-direction: column-reverse;
    align-items: center;
    height: 700px;
  }
  .footTitle {
    margin-top: 25px;
  }
}

@media screen and (max-width: 480px) {
  .gameFoot div {
    width: 200px;
  }

  .infoArea {
    text-align: center;
  }

  .footTitle {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .icon {
    margin-top: 20px;
  }
}
