body {
  background-color: #f3f1e9;
}
.aboutBox {
  display: flex;
  align-items: center;
  font-family: Montserrat-SemiBold;
  flex-direction: column;
  background-color: #f3f1e9;
}
.aboutBoxLogo {
  margin-top: 75px;
  width: 400px;
}
.team {
  margin: 50px 0;
  width: 400px;
  border: 2px solid black;
  border-radius: 10%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
}

.roster {
  margin-top: 50px;
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.aboutHead {
  font-size: 2rem;
  margin-top: 2rem;
  margin: 1rem 0 0 0;
}
.aboutPara {
  width: 80%;
  color: black;
  font-size: 20px;
  margin: 1rem 0 0 0;
  text-align: center;
  font-weight: lighter;
}

.teamPictures {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}

@media screen and (max-width: 1800px) {
  .roster {
    width: 75%;
  }
}

@media screen and (max-width: 1400px) {
  .roster {
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .roster {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .roster {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 1400px;
  }

  .teamPictures {
    flex-direction: column;
    align-items: center;
  }

  .aboutBoxLogo {
    width: 300px;
  }
  .team {
    width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .aboutPara {
    width: 95%;
    font-size: 16px;
  }
  .aboutBoxLogo {
    width: 220px;
  }
  .team {
    width: 200px;
  }
}
