.blogBox {
  display: flex;
  align-items: center;
  font-family: Montserrat-Regular;
  flex-direction: column;
  background-color: #f3f1e9;
}
.imgBox {
  width: 100%;
  height: 500px;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  flex-direction: column;
  background-image: url("../../Assets/devOffice.jpg");
}
.imgBox img {
  width: 25vw;
}
.imgBox h3 {
  color: white;
  font-size: 2vw;
  text-align: center;
  font-weight: lighter;
  border-bottom: 1px dotted white;
}
.blogBodyBox {
  width: 50%;
  margin-top: 3rem;
}
.blogBodyBox h3 {
  text-align: left;
  border-bottom: 1px dotted black;
}

@media screen and (max-width: 480px) {
  .imgBox img {
    width: 50vw;
  }
  .imgBox {
    padding: 0.5rem 0;
  }
  .imgBox h3 {
    font-size: 2.7vw;
  }
  .blogBodyBox {
    width: 90%;
    margin-top: 1rem;
  }
}
