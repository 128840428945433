.rocketBox {
  width: 100%;
}

.RocketGame {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.rocketBackground {
  width: 100%;
  height: 100%;
  z-index: -3;
  position: fixed;
}

.Asteroid1 {
  z-index: -2;
  right: -10%;
  top: 45%;
  width: 200px;
  position: fixed;
  margin-right: 15%;
  animation: rotation 50s infinite linear;
}

.Asteroid2 {
  z-index: -2;
  top: 10%;
  right: 20%;
  width: 100px;
  position: fixed;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.Asteroid3 {
  z-index: -2;
  left: 20%;
  bottom: 15%;
  width: 150px;
  position: fixed;
  margin-left: 10%;
  animation: rotation-3 50s infinite linear;
}

.MainShip {
  position: absolute;
  z-index: -2;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.Rocket_Buzzer {
  width: 200px;
  top: 5%;
  left: 10%;
  z-index: -2;
  position: absolute;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.Rocket_Cruiser {
  z-index: -2;
  top: 2%;
  right: 5%;
  width: 200px;
  position: absolute;
  animation-iteration-count: infinite;
}
.Rocket_Valor {
  bottom: 0;
  right: 0;
  width: 200px;
  position: absolute;
  margin-top: -8rem;
  animation-iteration-count: infinite;
}

.bannerMobileGoogle {
  z-index: 0;
  position: absolute;
  width: 300px;
  bottom: 0;
  right: 20%;
}

.bannerMobileApple {
  z-index: 0;
  position: absolute;
  width: 300px;
  bottom: 0;
  left: 20%;
}

.Rocket_Dagger {
  z-index: -2;
  left: 0;
  bottom: 0;
  width: 200px;
  position: absolute;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.Banner {
  z-index: -1;
  width: 100%;
  position: fixed;
  top: 30%;
}

.Rocket {
  top: 0;
  left: 10%;
  z-index: 2;
  width: 80vw;
  position: absolute;
  transform: translate(-50%, -50%);
  animation-iteration-count: infinite;
}

.aboutRocketGame {
  z-index: 10;
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 5rem;
  align-items: center;
  flex-direction: column;
  background-color: rgb(67, 55, 69);
}

.centerRocketGameContaint {
  width: 65%;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  font-family: Montserrat-Regular;
  padding-bottom: 5rem;
  flex-direction: column;
  color: black;
  z-index: 0;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;
}

.mobileLogo {
  margin: 25px 0;
}

.mobileLogo:hover {
  cursor: pointer;
}

.footBox {
  width: 100%;
  z-index: 0;
}

.mobileDownloadArea {
  display: none;
}

@media screen and (max-width: 1400px) {
  .Rocket_Dagger {
    bottom: 0%;
  }
  .Asteroid1 {
    top: 35%;
    width: 175px;
  }
  .Asteroid3 {
    bottom: 20%;
    left: 10%;
  }
}

@media screen and (max-width: 1200px) {
  .rocketBackground {
    width: 200%;
    overflow: hidden;
  }
  .RocketGame {
    height: 70vh;
  }
  .MainShip {
    width: 300px;
    top: 15%;
  }
  .Rocket_Buzzer {
    top: 10%;
    width: 150px;
  }
  .Rocket_Cruiser {
    width: 150px;
    top: 10%;
  }
  .Rocket_Dagger {
    bottom: 25%;
  }
  .Rocket_Valor {
    bottom: 25%;
  }
  .Asteroid1 {
    top: 32%;
    right: -15%;
    width: 150px;
  }
  .Asteroid2 {
    top: 15%;
  }
  .Asteroid3 {
    width: 125px;
    bottom: 35%;
  }
  .Banner {
    top: 30%;
  }

  .bannerMobileGoogle {
    bottom: 30%;
  }

  .bannerMobileApple {
    bottom: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .Asteroid1 {
    top: 30%;
    right: -15%;
    width: 125px;
  }
  .Asteroid2 {
    top: 8%;
    width: 75px;
  }

  .bannerMobileGoogle {
    width: 200px;
  }

  .bannerMobileApple {
    width: 200px;
  }
}

@media screen and (max-height: 800px) {
  .Rocket_Valor {
    display: none;
  }
  .Rocket_Dagger {
    display: none;
  }

  .bannerMobileGoogle {
    display: none;
  }

  .bannerMobileApple {
    display: none;
  }

  .mobileDownloadArea {
    display: flex;
    width: 85%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mobileLogo {
    width: 220px;
  }
}

@media screen and (max-width: 900px) {
  .centerRocketGameContaint {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .RocketGame {
    height: 70vh;
  }
  .MainShip {
    width: 250px;
    top: 10%;
  }
  .Rocket_Dagger {
    width: 175px;
  }

  .bannerMobileGoogle {
    bottom: 30%;
  }

  .bannerMobileApple {
    bottom: 30%;
  }
  .mobileLogo {
    width: 175px;
  }
}

@media screen and (max-width: 680px) {
  .RocketGame {
    height: 60vh;
  }
  .Rocket_Buzzer {
    display: none;
  }
  .Rocket_Dagger {
    display: none;
  }
  .Rocket_Valor {
    display: none;
  }
  .Asteroid1 {
    display: none;
  }
  .Asteroid2 {
    display: none;
  }
  .Asteroid3 {
    display: none;
  }
  .centerRocketGameContaint {
    width: 90%;
  }

  .bannerMobileGoogle {
    bottom: 40%;
    right: 5%;
  }

  .bannerMobileApple {
    bottom: 40%;
    left: 5%;
  }
}

@media screen and (max-width: 500px) {
  .Banner {
    top: 15%;
  }
  .MainShip {
    top: 8%;
    width: 100px;
  }
  .RocketGame {
    height: 35vh;
  }
  .Rocket_Cruiser {
    display: none;
  }
  .Asteroid3 {
    display: none;
  }
  .aboutRocketGame {
    padding-top: 0rem;
  }
  .centerRocketGameContaint {
    width: 85%;
    font-size: 1rem;
    text-align: center;
    padding-bottom: 1rem;
  }
  .rocketBackground {
    overflow: hidden;
    margin-top: -20vw;
  }

  .featureImage1 {
  }

  .featureImage2 {
  }

  .Asteroid1 {
    top: 22%;
    position: absolute;
  }

  .bannerMobileGoogle {
    display: none;
  }

  .bannerMobileApple {
    display: none;
  }

  .mobileDownloadArea {
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 300px) {
  .RocketGame {
    height: 25vh;
  }
  .MainShip {
    width: 70px;
    top: 5%;
  }
  .Asteroid1 {
    width: 50px;
    top: 15%;
    right: -15%;
  }
  .Banner {
    top: 10%;
  }

  .bannerMobileGoogle {
    bottom: 70%;
    width: 100px;
  }

  .bannerMobileApple {
    bottom: 70%;
    width: 100px;
  }
}

@media screen and (max-height: 850px) {
  .Rocket_Valor {
    display: none;
  }
  .Rocket_Dagger {
    display: none;
  }
}
