.drawerToggle {
  width: 30px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  margin-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.drawerToggle div {
  width: 90%;
  height: 3px;
  background-color: white;
}

@media (min-width: 1050px) {
  .drawerToggle {
    display: none;
  }
}
