.shifterCard {
  background-color: rgb(221, 221, 221);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 5%;
  transition: 0.3s;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  padding: 20px 10px;
  transition: transform 0.3s ease-out;
}

.shifterCard:hover {
  box-shadow: 0 14px 18px 0 rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

@media screen and (max-width: 800px) {
  .shiftTitleImage {
    max-width: 50%;
    max-height: 60px;
  }
}

@media screen and (max-width: 350px) {
  .shifterCard {
    padding: 10px 0;
    width: 260px;
  }
}
