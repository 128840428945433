.homeBox {
  display: flex;
  align-items: center;
  font-family: Montserrat-SemiBold;
  flex-direction: column;
  background-color: #f3f1e9;
}

.homeLogo {
  margin-top: 25px;
  height: 100%;
  width: 500px;
}

.homePara {
  font-size: 24px;
  color: black;
  text-align: center;
}

.circlesContainer {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.circles {
  display: flex;
  padding: 1.5rem 0;
  flex-direction: column !important;
  align-items: center;
  margin: 0px 20px;
}

.circles img {
  width: 250px;
  height: 250px;
  border-radius: 10rem;
  box-shadow: 0 12px 24px 0.5rem black;
  transition-duration: 0.5s;
}

.circles img:hover {
  box-shadow: 0 4px 10px 0.7rem rgb(88, 107, 148);
  cursor: pointer;
}

.leanLink {
  padding: 50px 40px;
  border-radius: 20px;
  font-size: 24px;
  color: black;
  text-decoration: none;
  font-family: Montserrat-Bold;
}

.leanLink:hover {
  color: white;
  background-size: 100%;
  background-position: 0%;
  background-image: url("../../Assets/ui/blackSplash.png");
  background-repeat: no-repeat;
  cursor: pointer;
}

.footBox {
  width: 100%;
  margin-top: 2rem;
  z-index: 0;
}

@media screen and (max-width: 750px) {
  .homeLogo {
    width: 400px;
    margin-top: 45px;
  }
  .circlesContainer {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .homeLogo {
    width: 250px;
  }
  .homePara {
    font-size: 18px;
  }
  .circles img {
    height: 200px;
    width: 200px;
  }
  .leanLink {
    font-size: 18px;
    text-align: center;
  }
}
