.rocketCard {
  width: 100%;
  border-radius: 0;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  border: 1px solid black;
}

.rocketDescription {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  font-family: Montserrat-SemiBold;
}

.rocketCardMobile {
  width: 60%;
  border-radius: 0;
  background-color: rgb(242, 242, 242);
}

.rocketDescription p {
  padding: 0 30px;
}

.headerImage {
  width: 800px;
  margin-top: 25px;
}

.header1 {
  max-height: 50px;
  width: 750px;
}

.header2 {
  max-height: 50px;
  width: 900px;
}

.header3 {
  max-height: 50px;
  width: 500px;
}

@media screen and (max-width: 1350px) {
  .header1 {
    width: 500px;
  }

  .header2 {
    width: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .rocketCardMobile {
    width: 80%;
  }
}

@media screen and (max-width: 1050px) {
  .header2 {
    width: 500px;
  }
}

@media screen and (max-width: 680px) {
  .rocketCardMobile {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .rocketDescription {
    flex-direction: column;
    padding: 5px;
  }

  .header1 {
    width: 300px;
  }

  .header2 {
    width: 300px;
  }

  .header3 {
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .header1 {
    width: 250px;
  }

  .header2 {
    width: 250px;
  }

  .header3 {
    width: 250px;
  }
}
