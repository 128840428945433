.sideDrawer {
  position: fixed;
  width: 280px;
  max-width: 80%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(-100%);
}

.headerlinkDrawer {
  color: white;
  font-size: 20px;
  text-decoration: none;
  font-family: Montserrat-Bold;
}

.headerlinkDrawer:hover {
  color: white;
}

@media (min-width: 1050px) {
  .sideDrawer {
    display: none;
  }
}
