.headerBox {
  z-index: 15;
  width: 100%;
  display: flex;
  color: white;
  position: fixed;
  align-items: center;
  background-color: rgb(12, 12, 12);
  justify-content: space-between;
}
.navLinks {
  display: flex;
  width: fit-content;
  justify-content: space-around;
}
.navLinks h5 {
  margin: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: lighter;
  font-family: Montserrat-Bold;
  padding-left: 10px;
}
.headerBox img {
  width: 15rem;
  cursor: pointer;
  margin-top: 0.5rem;
  object-fit: contain;
}

.catLogo {
  width: 50px;
}

.Headerlink {
  color: white;
  text-decoration: none;
}

.Headerlink:hover {
  color: black;
  background-size: 100%;
  background-position: 0%;
  background-image: url("../../Assets/ui/background-splash-white-100.png");
}

@media (max-width: 1050px) {
  .navLinks {
    display: none;
  }
}

@media (max-width: 480px) {
  .headerBox img {
    width: 150px;
  }

  .catLogo {
    display: none;
  }
}
