.card {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 5%;
  transition: 0.3s;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  transition: transform 0.3s ease-in-out;
}

.line {
  border-top: 3px solid black;
  width: 50%;
}

.card h3,
.card h5 {
  margin: 0;
  text-align: center;
}

.card h3 {
  font-family: Montserrat-Bold;
}

p {
  padding: 0 10px;
}

/* Custom Card Classes added*/
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
  transform: translateY(-5%);
}

.description {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  font-family: Montserrat-SemiBold;
}
