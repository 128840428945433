.shiftBox {
  width: 100%;
}

.Game {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.logo {
  left: 50%;
  top: 15rem;
  z-index: -1;
  width: 800px;
  position: fixed;
  transform: translate(-50%, -50%);
}

.background {
  width: 100%;
  height: 100%;
  z-index: -4;
  position: fixed;
}

.earth {
  right: 0;
  top: 10%;
  z-index: -3;
  width: 35vw;
  position: absolute;
  margin-top: -5vw;
}

.spaceShip {
  left: 0;
  bottom: 5%;
  z-index: -3;
  width: 500px;
  position: absolute;
  animation-duration: 25s;
  animation-iteration-count: infinite;
}

.plane {
  top: 70%;
  left: 50%;
  z-index: -2;
  width: 600px;
  position: absolute;
  transform: translate(-50%, -50%);
  animation-iteration-count: infinite;
}

.aboutGame {
  width: 100%;
  display: flex;
  padding-top: 30px;
  align-items: center;
  flex-direction: column;
  background-color: #f3f1e9;
  font-family: Montserrat-SemiBold;
  background-color: rgb(71, 71, 71);
}

.shifterSub {
  font-family: Montserrat-Bold;
  font-size: 24px;
}

.centerGameContaint {
  width: 65%;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  padding-bottom: 5rem;
  flex-direction: column;
  text-align: center;
  color: black;
  border: 2px solid;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
  background-color: rgb(202, 209, 215);
}

.features {
  margin-top: 50px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titleLine {
  border-top: 2px solid black;
  width: 80%;
}

.shiftStory {
  max-height: 60px;
  max-width: 85%;
}

.shiftGameplay {
  max-height: 60px;
  max-width: 45%;
}

.endAction {
  text-align: center;
}

@media screen and (max-height: 750px) {
  .spaceShip {
    display: none;
  }
  .plane {
    width: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .features {
    width: 80%;
  }
  .centerGameContaint {
    width: 80%;
  }
}

@media screen and (max-width: 1400px) {
  .features {
    width: 90%;
  }
}

@media screen and (max-width: 1250px) {
  .Game {
    height: 100vh;
  }

  .centerGameContaint {
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .Game {
    height: 80vh;
  }
  .logo {
    width: 600px;
    top: 25%;
  }
  .plane {
    width: 500px;
    top: 58%;
  }
  .spaceShip {
    width: 300px;
    bottom: 20%;
  }
  .features {
    width: 95%;
  }
}

@media screen and (max-width: 1050px) {
  .features {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 1100px;
  }
}

@media screen and (max-width: 900px) {
  .spaceShip {
    display: none;
  }

  .plane {
    width: 400px;
  }

  .centerGameContaint {
    width: 80%;
  }
}

@media screen and (max-width: 650px) {
  .Game {
    height: 50vh;
  }
  .earth {
    top: 10%;
  }
  .logo {
    width: 300px;
    top: 25%;
  }
  .plane {
    width: 200px;
    top: 40%;
  }

  .centerGameContaint h2 {
    font-size: 18px;
  }
  .centerGameContaint p {
    font-size: 14px;
  }

  .features {
    height: 800px;
  }
}

@media screen and (max-width: 480px) {
  .background {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: -20vw;
  }
  .earth {
    display: none;
  }
  .logo {
    width: 200px;
    top: 18%;
  }

  .EXAMPLE {
    top: 4rem;
    width: 7rem;
  }
  .plane {
    top: 30%;
    width: 125px;
  }
  .Game {
    height: 40vh;
  }
  .aboutGame {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 300px) {
  .logo {
    top: 15%;
  }
  .Game {
    height: 35vh;
  }
  .plane {
    top: 28%;
    width: 100px;
  }
  .spaceShip {
    bottom: 0%;
  }
}
